@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --gird-width: 30px;
    --gird-height: 30px;
    --line-color: #999;
    --dot-color: #ccc;
    --dot-size: 2px;
    --point-gap-width: calc(var(--gird-width) * 4);
    --point-gap-height: calc(var(--gird-height) * 4);
}
html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    font-family: Seravek, 'Gill Sans Nova', Ubuntu, Calibri, 'DejaVu Sans',
        source-sans-pro, sans-serif;
}
.grid-bg {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ba-grid {
    position: relative;
    z-index: 1;

    background-image: 
  /*     radial-gradient(ellipse at center, #2883b6 0%, #084688 100%), */ radial-gradient(
            ellipse at bottom left,
            rgba(104, 128, 138, 0.4) 10%,
            rgba(138, 114, 76, 0) 40%
        ),
        linear-gradient(
            to bottom,
            rgba(57, 173, 219, 0.25),
            rgba(42, 60, 87, 0.4)
        ),
        linear-gradient(135deg, #670d10, #092756);
}

.ba-grid:before,
.ba-grid:after,
.inner:before,
.inner:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    mix-blend-mode: overlay;
}

.ba-grid:before {
    background-image: linear-gradient(var(--line-color), transparent 1px),
        linear-gradient(to right, var(--line-color), transparent 1px);
    background-size: 100% var(--gird-height), var(--gird-width) 100%;
}

.ba-grid:after {
    background-image: radial-gradient(var(--dot-color) 1px, transparent 1px);
    background-size: var(--point-gap-width) var(--point-gap-height);
    background-position: calc(var(--point-gap-width) / 2)
        calc(var(--point-gap-height) / 2);
}

.inner:before,
.inner:after {
    background-image: radial-gradient(
        var(--dot-color) var(--dot-size),
        transparent var(--dot-size)
    );
    background-size: var(--point-gap-width) var(--point-gap-height);
    background-position: calc(var(--point-gap-width) / 2)
        calc(var(--point-gap-height) / 2);
}

.inner:after {
    transform: scale(1.1, 1.1);
}

.inner:before {
    /*   background-image: radial-gradient(var(--dot-color) calc(var(--dot-size) + 1px), transparent calc(var(--dot-size) + 1px)); */
    transform: scale(1.2, 1.2);
}

.anim:after {
    animation: 6.4s linear move infinite;
}

.anim .inner:after {
    animation: 3.2s linear move infinite;
}

.anim .inner:before {
    animation: 1.6s linear move infinite;
}

@keyframes move {
    0% {
        background-position: calc(var(--point-gap-width) / 2)
            calc(var(--point-gap-height) / 2);
    }
    100% {
        background-position: calc(var(--point-gap-width) / 2)
            calc(var(--point-gap-height) / 2 * -1);
    }
}

.typed-cursor {
    color: white;
    font-size: 1.5rem;
}
